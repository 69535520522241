import React from 'react';
import {Text, View, TouchableOpacity} from 'react-native';
import I18n from 'i18n-js';

import {styles} from './styles';
import InfoTextWithTooltip from './infoTextWithTooltip';

const RenderCartHeader = ({name, id, screenWidth}) => {
  return (
    <>
      <View style={styles.cartHeader}>
        <View />
        <Text
          style={[styles.cartTitle, {fontSize: screenWidth < 768 ? 18 : 20}]}>
          {name}
        </Text>
        <TouchableOpacity style={styles.printButton}>
          <i className="icon-print" style={{fontSize: 24, color: '#ffff'}}></i>
          <Text
            style={[
              styles.printButtonText,
              {fontSize: screenWidth < 768 ? 12 : 14}
            ]}>
            {I18n.t('scanToCart.printToteLabel')}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.infoContainer}>
        <View style={styles.headerLeft}>
          <Text style={styles.headerText}>
            {I18n.t('scanToCart.rowIdLabel')}
          </Text>
        </View>
        <InfoTextWithTooltip cartId={id} screenWidth={screenWidth} />
        <View style={styles.headerRight}>
          <Text style={styles.headerText}>
            {I18n.t('scanToCart.totesInRowLabel')}
          </Text>
        </View>
      </View>
    </>
  );
};
export default RenderCartHeader;
