import React, {useState, useEffect} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  TextInput,
  Dimensions
} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {styles} from './styles';
import RenderCartHeader from './renderCartHeader';
import ToteRowItemsControllers from './toteRowItemsControllers';
import I18n from 'i18n-js';

const ScanToCartSettings = () => {
  const [screenWidth, setScreenWidth] = useState(
    Dimensions.get('window').width
  );
  const [carts, setCarts] = useState([
    {
      id: 'C01',
      name: 'Cart 1',
      rows: [
        {id: 'A', totes: 5},
        {id: 'B', totes: 5}
      ]
    }
  ]);

  useEffect(() => {
    const updateWidth = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateWidth);
    return () => {
      if (Dimensions.removeEventListener) {
        Dimensions.removeEventListener('change', updateWidth);
      }
    };
  }, []);

  const getNextCartId = () => {
    const nextNum = carts.length + 1;
    return `C${nextNum.toString().padStart(2, '0')}`;
  };

  const getNextRowId = rows => {
    if (rows.length === 0) return 'A';
    const lastRow = rows[rows.length - 1].id;
    if (lastRow.length === 1 && lastRow >= 'A' && lastRow <= 'Z') {
      return String.fromCharCode(lastRow.charCodeAt(0) + 1);
    }
    return String.fromCharCode('A'.charCodeAt(0) + rows.length);
  };

  const deleteRow = (cartId, rowIndex) => {
    setCarts(
      carts.map(cart => {
        if (cart.id === cartId) {
          const newRows = cart.rows.filter((_, index) => index !== rowIndex);
          return {...cart, rows: newRows};
        }
        return cart;
      })
    );
  };

  const addCart = () => {
    const newCartId = getNextCartId();
    setCarts([
      ...carts,
      {
        id: newCartId,
        name: `Cart ${carts.length + 1}`,
        rows: [{id: 'A', totes: 5}]
      }
    ]);
  };

  const addRow = cartId => {
    setCarts(
      carts.map(cart => {
        if (cart.id === cartId) {
          return {
            ...cart,
            rows: [...cart.rows, {id: getNextRowId(cart.rows), totes: 5}]
          };
        }
        return cart;
      })
    );
  };

  const updateRowId = (cartId, rowIndex, newId) => {
    setCarts(
      carts.map(cart => {
        if (cart.id === cartId) {
          const newRows = [...cart.rows];
          newRows[rowIndex] = {...newRows[rowIndex], id: newId};
          return {...cart, rows: newRows};
        }
        return cart;
      })
    );
  };

  const updateToteCount = (cartId, rowIndex, newValue) => {
    const numValue = parseInt(newValue, 10);
    if (isNaN(numValue)) return;

    setCarts(
      carts.map(cart => {
        if (cart.id === cartId) {
          const newRows = [...cart.rows];
          const newTotes = Math.max(1, Math.min(10, numValue));
          newRows[rowIndex] = {...newRows[rowIndex], totes: newTotes};
          return {...cart, rows: newRows};
        }
        return cart;
      })
    );
  };

  const copyToteId = async (cartId, rowId, toteNumber) => {
    const barcodeValue = `${rowId}-${toteNumber}-${cartId}`;
    await Clipboard.setStringAsync(barcodeValue);
  };

  const renderTote = ({item: toteNumber, rowId, cartId, totalTotes}) => {
    const availableWidth = screenWidth * 0.68 - 280;
    const toteWidth = Math.max(availableWidth / totalTotes - 10, 60);
    return (
      <TouchableOpacity
        testID="copyToteButtonId"
        style={[styles.tote, {width: toteWidth, maxWidth: toteWidth}]}
        onPress={() => copyToteId(cartId, rowId, toteNumber)}>
        <Text
          numberOfLines={1}
          style={[styles.toteText, {fontSize: screenWidth < 768 ? 12 : 14}]}>
          {`${rowId}-${toteNumber}`}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderRow = ({item: row, index, cartId}) => {
    const totesToRender = screenWidth < 900 ? 1 : row.totes;
    return (
      <View style={styles.rowContainer}>
        <TouchableOpacity
          testID="deleteCartRowButton"
          style={styles.deleteButton}
          onPress={() => deleteRow(cartId, index)}>
          <i className=" icon-cancel" style={{fontSize: 24, color: '#ffff'}} />
        </TouchableOpacity>

        <TextInput
          testID="rowIdInput"
          style={styles.rowInput}
          autoFocus={true}
          value={row.id}
          onChangeText={newId => updateRowId(cartId, index, newId)}
          autoCapitalize="characters"
        />

        <View style={styles.toteListContainer}>
          {[...Array(totesToRender)].map((_, i) => (
            <View key={`${row.id}-${i + 1}`}>
              {renderTote({
                item: i + 1,
                rowId: row.id,
                cartId,
                totalTotes: row.totes
              })}
            </View>
          ))}
        </View>

        <ToteRowItemsControllers
          initialValue={row.totes}
          onUpdate={newValue => updateToteCount(cartId, index, newValue)}
        />
      </View>
    );
  };
  const renderCart = ({item: cart}) => (
    <View style={styles.cartContainer}>
      <RenderCartHeader
        name={cart.name}
        screenWidth={screenWidth}
        id={cart.id}
      />

      <FlatList
        data={cart.rows}
        renderItem={({item, index}) =>
          renderRow({item, index, cartId: cart.id})
        }
        keyExtractor={(item, index) => `${cart.id}-${item.id}-${index}`}
      />

      <TouchableOpacity
        testID="addCartRowButton"
        style={styles.addRowButton}
        onPress={() => addRow(cart.id)}>
        <i className="icon-plus" style={{color: '#ffff', fontSize: 18}}></i>
        <Text
          style={[styles.addRowText, {fontSize: screenWidth < 768 ? 12 : 14}]}>
          {I18n.t('scanToCart.addRowLabel')}
        </Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={[styles.container, {padding: screenWidth < 768 ? 10 : 18}]}>
      <Text style={[styles.header, {fontSize: screenWidth < 768 ? 20 : 24}]}>
        {I18n.t('scanToCart.cartsLabel')}
      </Text>
      <View style={styles.cardsContainer}>
        <FlatList
          data={carts}
          renderItem={renderCart}
          keyExtractor={item => item.id}
        />
        <TouchableOpacity
          testID="addCartButton"
          style={styles.addCartButton}
          onPress={addCart}>
          <i className="icon-plus" style={{color: '#ffff', fontSize: 25}}></i>
          <Text
            style={[
              styles.addCartText,
              {fontSize: screenWidth < 768 ? 12 : 14}
            ]}>
            {I18n.t('scanToCart.addCartLabel')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ScanToCartSettings;
